import type { Subject } from 'rxjs';

/**
 * Make a {@link Subject}-like property that watches the value of the property `propertyToWatch`.
 *
 * @remarks
 * Users must initialize the property in the class definition e.g.:
 * ```
 * @Subjectize('someProp')
 * public someProp$ = new BehaviorSubject<T>(1);
 * ```
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function Subjectize<T>(propertyToWatch: string): PropertyDecorator {
  return (proto: unknown, propKey: string) => {
    // emit current value when initializing the Subject
    extendPropertyAccessor(proto, propKey, {
      set(value: Subject<T>) {
        if (this[propertyToWatch] !== undefined) {
          value.next(this[propertyToWatch]);
        }
      },
    });

    extendPropertyAccessor(proto, propertyToWatch, {
      set(value: unknown) {
        if (this[propKey] === undefined) {
          return;
        }
        this[propKey].next(value);
      },
    });
  };
}
type GetterType = () => unknown;
type SetterType = (_: unknown) => void;
function extendPropertyAccessor(
  proto: unknown,
  prop: string,
  config: { get?: GetterType; set?: SetterType } = {},
): void {
  const internalKey = Symbol(prop);
  const propDescriptor = Object.getOwnPropertyDescriptor(proto, prop);
  const { get: getterFunc, set: setterFunc } = config;

  const get =
    getterFunc ||
    propDescriptor?.get ||
    function() {
      // @ts-ignore
      if (propDescriptor?.value && this[internalKey] === undefined) {
        // @ts-ignore
        this[internalKey] = propDescriptor.value;
      }

      // @ts-ignore
      return this[internalKey];
    };

  const set = function(value: unknown) {
    // @ts-ignore
    this[internalKey] = value;
    // keep existing setter working
    if (propDescriptor?.set) {
      // @ts-ignore
      propDescriptor.set.call(this, value);
    }
    // @ts-ignore
    setterFunc?.call(this, value);
  };

  Object.defineProperty(proto, prop, {
    get,
    set,
    configurable: true,
  });
}
